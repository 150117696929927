import React from "react"
import BlocksRenderer from "../components/blocks-renderer"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import Headings from "../components/sg-headings"
import { BgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"
import Cta from "../components/call_to_action"

// Add Page Head component and pass in SEO variables from GraphQL
export const Head = ({ data }) => {
  const title = data.strapiAbout.title
  const description = data.strapiGlobal.defaultSeo.metaDescription
  return (
    <SEO title={title} description={description} />
  )
}

// Page variables
export default function AboutPage ( { data } ) {
const background = getImage(data.aboutBg)
const aboutWhy = "I want to show you how big companies use data without the price tag."
const headline = data.strapiAbout.title
const blocks = data.strapiAbout.blocks

// Return the layout   
return (
        <Layout>
          <div className="relative w-full h-full bg-gradient-to-r from-gray-800 to-red-700">
            <BgImage 
              image={background} 
              alt="Smoke drifting right" 
              className="absolute h-96 bg-center object-cover mix-blend-overlay" />
                <div className="container absolute top-0 left-1/2 -translate-x-1/2">
                  <Headings title={headline} description={aboutWhy} />
              </div>  
          </div>
          <div classname="prose"><BlocksRenderer blocks={blocks} /></div>
          <Cta />
        </Layout>
      )
    }

// query from Strapi

export const query = graphql`    
query AboutQuery {
    strapiGlobal {
        defaultSeo {
          metaTitle
          metaDescription
        }
      }
      strapiAbout {
        title
        blocks {
         ...Blocks
        }
      }
      aboutBg: file(relativePath: {eq: "images/smoke_about.jpg"}) {
        childImageSharp {
          gatsbyImageData
        }
      }
  }
`
